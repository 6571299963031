var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useRef, useState } from "react";
import { usePrevalenceFilters } from "./PrevalenceDataContext";
import { Box, CircularProgress, Typography, Grid, Button, Select, MenuItem, FormControl, InputLabel, Pagination, } from "@mui/material";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { useTranslation } from "react-i18next";
Chart.register.apply(Chart, __spreadArray([], __read(registerables), false));
var italicWords = [
    "Salmonella",
    "coli",
    "E.",
    "Bacillus",
    "cereus",
    "monocytogenes",
    "Clostridioides",
    "difficile",
    "Yersinia",
    "Listeria",
    "enterocolitica",
    "Vibrio",
    "Baylisascaris",
    "procyonis",
    "Echinococcus",
    "Campylobacter",
];
var formatMicroorganismName = function (microName) {
    if (!microName) {
        console.warn("Received null or undefined microorganism name");
        return React.createElement(React.Fragment, null);
    }
    var words = microName
        .split(/([-\s])/)
        .filter(function (part) { return part.length > 0; });
    return (React.createElement(React.Fragment, null, words.map(function (word, index) {
        if (word.trim() === "" || word === "-") {
            return word;
        }
        var italic = italicWords.some(function (italicWord) {
            return word.toLowerCase().includes(italicWord.toLowerCase());
        });
        return italic ? (React.createElement("i", { key: index }, word)) : (React.createElement("span", { key: index }, word));
    })));
};
var getCurrentTimestamp = function () {
    var now = new Date();
    return now.toISOString().replace(/[-:.]/g, "");
};
var getFormattedDate = function () {
    var now = new Date();
    return now.toLocaleDateString();
};
var PrevalenceChart = function () {
    var _a = usePrevalenceFilters(), selectedMicroorganisms = _a.selectedMicroorganisms, prevalenceData = _a.prevalenceData, loading = _a.loading;
    var chartRefs = useRef({});
    var t = useTranslation(["PrevalencePage"]).t;
    var _b = __read(useState(selectedMicroorganisms[0] || ""), 2), currentMicroorganism = _b[0], setCurrentMicroorganism = _b[1];
    var _c = __read(useState(1), 2), currentPage = _c[0], setCurrentPage = _c[1];
    var chartsPerPage = 2;
    var yearOptions = Array.from({ length: 14 }, function (_, i) { return 2009 + i; }).reverse();
    var generateChartData = function () {
        var chartData = {};
        prevalenceData.forEach(function (entry) {
            if (entry.microorganism === currentMicroorganism) {
                var key = "".concat(entry.sampleOrigin, "-").concat(entry.matrix, "-").concat(entry.samplingStage);
                if (!chartData[key]) {
                    chartData[key] = {};
                }
                chartData[key][entry.samplingYear] = {
                    x: entry.percentageOfPositive,
                    y: entry.samplingYear,
                    ciMin: entry.ciMin,
                    ciMax: entry.ciMax,
                    numberOfSamples: entry.numberOfSamples,
                    numberOfPositive: entry.numberOfPositive,
                };
            }
        });
        return chartData;
    };
    var chartData = generateChartData();
    var chartKeys = Object.keys(chartData);
    var totalCharts = chartKeys.length;
    var totalPages = Math.ceil(totalCharts / chartsPerPage);
    var displayedCharts = chartKeys.slice((currentPage - 1) * chartsPerPage, currentPage * chartsPerPage);
    var isBelow25Percent = Object.values(chartData)
        .flatMap(function (yearData) {
        return Object.values(yearData).every(function (data) { return data.ciMax <= 25; });
    })
        .every(Boolean);
    var xAxisMax = isBelow25Percent ? 25 : 100;
    var drawErrorBars = function (chart) {
        var ctx = chart.ctx;
        chart.data.datasets.forEach(function (dataset, i) {
            var meta = chart.getDatasetMeta(i);
            meta.data.forEach(function (bar, index) {
                var dataPoint = dataset.data[index];
                if (dataPoint && (dataPoint.ciMin || dataPoint.ciMax)) {
                    var xMin = chart.scales.x.getPixelForValue(dataPoint.ciMin);
                    var xMax = chart.scales.x.getPixelForValue(dataPoint.ciMax);
                    var y = bar.y;
                    ctx.save();
                    ctx.strokeStyle = "black";
                    ctx.lineWidth = 2;
                    ctx.beginPath();
                    ctx.moveTo(xMin, y);
                    ctx.lineTo(xMax, y);
                    ctx.stroke();
                    ctx.beginPath();
                    ctx.moveTo(xMin, y - 5);
                    ctx.lineTo(xMin, y + 5);
                    ctx.stroke();
                    ctx.beginPath();
                    ctx.moveTo(xMax, y - 5);
                    ctx.lineTo(xMax, y + 5);
                    ctx.stroke();
                    ctx.restore();
                }
            });
        });
    };
    var errorBarTooltipPlugin = {
        id: "customErrorBarsTooltip",
        afterEvent: function (chart, args) {
            var event = args.event;
            var tooltip = chart.tooltip;
            var mouseX = event.x;
            var mouseY = event.y;
            var foundErrorBar = false;
            chart.data.datasets.forEach(function (dataset, i) {
                var meta = chart.getDatasetMeta(i);
                meta.data.forEach(function (bar, index) {
                    var dataPoint = dataset.data[index];
                    if (dataPoint && (dataPoint.ciMin || dataPoint.ciMax)) {
                        var xMin = chart.scales.x.getPixelForValue(dataPoint.ciMin);
                        var xMax = chart.scales.x.getPixelForValue(dataPoint.ciMax);
                        var y = bar.y;
                        if (mouseX >= xMin &&
                            mouseX <= xMax &&
                            Math.abs(mouseY - y) < 5) {
                            if (tooltip &&
                                typeof tooltip.setActiveElements === "function") {
                                tooltip.setActiveElements([
                                    {
                                        datasetIndex: i,
                                        index: index,
                                    },
                                ], { x: mouseX, y: mouseY });
                            }
                            foundErrorBar = true;
                        }
                    }
                });
            });
            if (!foundErrorBar &&
                tooltip &&
                typeof tooltip.setActiveElements === "function") {
                tooltip.setActiveElements([], { x: 0, y: 0 });
            }
        },
    };
    var downloadChart = function (chartRef, chartKey) {
        if (chartRef.current) {
            var chartInstance = chartRef.current;
            var timestamp = getCurrentTimestamp();
            var canvas = chartInstance.canvas;
            var ctx = canvas.getContext("2d");
            if (ctx) {
                var tempCanvas = document.createElement("canvas");
                var tempCtx_1 = tempCanvas.getContext("2d");
                var extraHeight = 40;
                tempCanvas.width = canvas.width;
                tempCanvas.height = canvas.height + extraHeight;
                if (tempCtx_1) {
                    tempCtx_1.fillStyle = "white";
                    tempCtx_1.fillRect(0, 0, tempCanvas.width, tempCanvas.height);
                    tempCtx_1.drawImage(canvas, 0, extraHeight);
                    var words = currentMicroorganism
                        .split(/([-\s])/)
                        .filter(function (part) { return part.length > 0; });
                    var totalWidth_1 = 0;
                    words.forEach(function (word) {
                        var italic = italicWords.some(function (italicWord) {
                            return word
                                .toLowerCase()
                                .includes(italicWord.toLowerCase());
                        });
                        tempCtx_1.font = italic
                            ? "italic 20px Arial"
                            : "20px Arial";
                        totalWidth_1 += tempCtx_1.measureText(word).width + 2;
                    });
                    var xPos_1 = (tempCanvas.width - totalWidth_1) / 2;
                    var yPos_1 = 30;
                    words.forEach(function (word) {
                        var italic = italicWords.some(function (italicWord) {
                            return word
                                .toLowerCase()
                                .includes(italicWord.toLowerCase());
                        });
                        tempCtx_1.font = italic
                            ? "italic 20px Arial"
                            : "20px Arial";
                        tempCtx_1.fillStyle = "black";
                        tempCtx_1.fillText(word, xPos_1, yPos_1);
                        xPos_1 += tempCtx_1.measureText(word).width + 2;
                    });
                    var link = document.createElement("a");
                    link.href = tempCanvas.toDataURL();
                    link.download = "".concat(chartKey, "-").concat(timestamp, ".png");
                    link.click();
                }
            }
        }
    };
    var downloadAllCharts = function () { return __awaiter(void 0, void 0, void 0, function () {
        var zip, timestamp, chartPromises, content;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    zip = new JSZip();
                    timestamp = getCurrentTimestamp();
                    chartPromises = Object.keys(chartRefs.current).map(function (key) { return __awaiter(void 0, void 0, void 0, function () {
                        var chartRef, chartInstance, canvas, ctx, tempCanvas, tempCtx_2, extraHeight, words, totalWidth_2, xPos_2, yPos_2, base64Image;
                        return __generator(this, function (_a) {
                            chartRef = chartRefs.current[key];
                            if (chartRef && chartRef.current) {
                                chartInstance = chartRef.current;
                                canvas = chartInstance.canvas;
                                ctx = canvas.getContext("2d");
                                if (ctx) {
                                    tempCanvas = document.createElement("canvas");
                                    tempCtx_2 = tempCanvas.getContext("2d");
                                    extraHeight = 40;
                                    tempCanvas.width = canvas.width;
                                    tempCanvas.height = canvas.height + extraHeight;
                                    if (tempCtx_2) {
                                        tempCtx_2.fillStyle = "white";
                                        tempCtx_2.fillRect(0, 0, tempCanvas.width, tempCanvas.height);
                                        tempCtx_2.drawImage(canvas, 0, extraHeight);
                                        words = currentMicroorganism
                                            .split(/([-\s])/)
                                            .filter(function (part) { return part.length > 0; });
                                        totalWidth_2 = 0;
                                        words.forEach(function (word) {
                                            var italic = italicWords.some(function (italicWord) {
                                                return word
                                                    .toLowerCase()
                                                    .includes(italicWord.toLowerCase());
                                            });
                                            tempCtx_2.font = italic
                                                ? "italic 20px Arial"
                                                : "20px Arial";
                                            totalWidth_2 +=
                                                tempCtx_2.measureText(word).width + 2;
                                        });
                                        xPos_2 = (tempCanvas.width - totalWidth_2) / 2;
                                        yPos_2 = 30;
                                        words.forEach(function (word) {
                                            var italic = italicWords.some(function (italicWord) {
                                                return word
                                                    .toLowerCase()
                                                    .includes(italicWord.toLowerCase());
                                            });
                                            tempCtx_2.font = italic
                                                ? "italic 20px Arial"
                                                : "20px Arial";
                                            tempCtx_2.fillStyle = "black";
                                            tempCtx_2.fillText(word, xPos_2, yPos_2);
                                            xPos_2 += tempCtx_2.measureText(word).width + 2;
                                        });
                                        base64Image = tempCanvas
                                            .toDataURL()
                                            .split(",")[1];
                                        zip.file("".concat(key, "-").concat(timestamp, ".png"), base64Image, {
                                            base64: true,
                                        });
                                    }
                                }
                            }
                            return [2];
                        });
                    }); });
                    return [4, Promise.all(chartPromises)];
                case 1:
                    _a.sent();
                    return [4, zip.generateAsync({ type: "blob" })];
                case 2:
                    content = _a.sent();
                    saveAs(content, "charts-".concat(timestamp, ".zip"));
                    return [2];
            }
        });
    }); };
    var logoPlugin = {
        id: "logoPlugin",
        beforeDraw: function (chart) {
            var ctx = chart.ctx;
            ctx.save();
            ctx.globalCompositeOperation = "destination-over";
            ctx.fillStyle = "white";
            ctx.fillRect(0, 0, chart.width, chart.height);
            ctx.restore();
        },
        afterDraw: function (chart) {
            var ctx = chart.ctx;
            var img = new Image();
            img.src = "/assets/bfr_logo.png";
            img.onload = function () {
                var extraPadding = 20;
                var logoWidth = 60;
                var logoHeight = 27;
                var logoX = chart.chartArea.left + 20;
                var logoY = chart.chartArea.bottom + extraPadding;
                ctx.drawImage(img, logoX, logoY, logoWidth, logoHeight);
                var dateText = "".concat(t("Generated_on"), ": ").concat(getFormattedDate());
                ctx.font = "10px Arial";
                ctx.fillStyle = "#000";
                ctx.textAlign = "right";
                ctx.fillText(dateText, chart.width - 10, chart.height - 5);
            };
        },
    };
    return (React.createElement(Box, { sx: { padding: 0, position: "relative", minHeight: "100vh" } },
        React.createElement(Box, { sx: {
                position: "sticky",
                top: 0,
                zIndex: 1000,
                padding: 2,
                backgroundColor: "rgb(219, 228, 235)",
            } },
            React.createElement(FormControl, { fullWidth: true, sx: { mb: 1 }, variant: "outlined" },
                React.createElement(InputLabel, { id: "microorganism-select-label", shrink: true }, "Select Microorganism"),
                React.createElement(Select, { labelId: "microorganism-select-label", value: currentMicroorganism, onChange: function (event) {
                        return setCurrentMicroorganism(event.target.value);
                    }, label: "Select Microorganism", sx: { backgroundColor: "#f5f5f5" }, renderValue: function (selected) { return (React.createElement(Typography, { component: "span" }, formatMicroorganismName(selected))); } }, selectedMicroorganisms.map(function (microorganism) { return (React.createElement(MenuItem, { key: microorganism, value: microorganism },
                    React.createElement(Typography, { component: "span" }, formatMicroorganismName(microorganism)))); })))),
        loading ? (React.createElement(CircularProgress, null)) : (React.createElement(React.Fragment, null, Object.keys(chartData).length === 0 ? (React.createElement(Typography, { variant: "h6" }, t("No_data_available"))) : (React.createElement(React.Fragment, null,
            React.createElement(Grid, { container: true, spacing: 4 }, displayedCharts.map(function (key) {
                var refKey = "".concat(key, "-").concat(currentMicroorganism);
                if (!chartRefs.current[refKey]) {
                    chartRefs.current[refKey] =
                        React.createRef();
                }
                return (React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 6, lg: 6, key: refKey },
                    React.createElement(Box, { sx: {
                            backgroundColor: "white",
                            padding: 4,
                            borderRadius: 2,
                            boxShadow: 2,
                            margin: "0 15px",
                        } },
                        React.createElement(Typography, { variant: "h5", align: "center", gutterBottom: true, sx: {
                                minHeight: "60px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "normal",
                                wordWrap: "break-word",
                            } }, formatMicroorganismName(currentMicroorganism)),
                        React.createElement(Box, { sx: { marginBottom: 4 } },
                            React.createElement(Bar, { data: {
                                    labels: yearOptions,
                                    datasets: [
                                        {
                                            label: key,
                                            data: yearOptions.map(function (year) {
                                                var _a;
                                                return ((_a = chartData[key]) === null || _a === void 0 ? void 0 : _a[year]) || {
                                                    x: 0,
                                                    y: year,
                                                    ciMin: 0,
                                                    ciMax: 0,
                                                };
                                            }),
                                            backgroundColor: "#".concat(Math.floor(Math.random() *
                                                16777215).toString(16)),
                                        },
                                    ],
                                }, options: {
                                    indexAxis: "y",
                                    scales: {
                                        x: {
                                            title: {
                                                display: true,
                                                text: t("Prevalence %"),
                                            },
                                            beginAtZero: true,
                                            max: xAxisMax,
                                        },
                                        y: {
                                            title: {
                                                display: true,
                                                text: t("Year"),
                                            },
                                            reverse: false,
                                            ticks: {
                                                callback: function (_, index) {
                                                    return yearOptions[index];
                                                },
                                            },
                                        },
                                    },
                                    plugins: {
                                        legend: {
                                            labels: {
                                                padding: 5,
                                            },
                                        },
                                        tooltip: {
                                            backgroundColor: "rgba(0, 0, 0, 1)",
                                            titleFont: {
                                                size: 14,
                                            },
                                            bodyFont: {
                                                size: 12,
                                            },
                                            displayColors: true,
                                            borderColor: "#fff",
                                            borderWidth: 1,
                                            caretPadding: 120,
                                            yAlign: "center",
                                            callbacks: {
                                                label: function (context) {
                                                    var _a;
                                                    var year = parseInt(context.label, 10);
                                                    var data = ((_a = chartData[key]) === null || _a === void 0 ? void 0 : _a[year]) ||
                                                        {};
                                                    var rawData = context.raw;
                                                    return [
                                                        "".concat(t("Prevalence"), ": ").concat(rawData.x, "%"),
                                                        "".concat(t("CI_min"), ": ").concat(data.ciMin),
                                                        "".concat(t("CI_max"), ": ").concat(data.ciMax),
                                                        "".concat(t("Samples"), ": ").concat(data.numberOfSamples),
                                                        "".concat(t("Positive"), ": ").concat(data.numberOfPositive),
                                                    ];
                                                },
                                            },
                                        },
                                    },
                                    animation: false,
                                }, plugins: [
                                    errorBarTooltipPlugin,
                                    {
                                        id: "customErrorBars",
                                        afterDraw: function (chart) {
                                            return drawErrorBars(chart);
                                        },
                                    },
                                    logoPlugin,
                                ], ref: chartRefs.current[refKey] }),
                            React.createElement(Box, { sx: {
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: 2,
                                } },
                                React.createElement(Button, { variant: "contained", size: "medium", onClick: function () {
                                        return downloadChart(chartRefs
                                            .current[refKey], refKey);
                                    }, sx: {
                                        textTransform: "none",
                                    } }, t("Download_Chart")))))));
            })),
            React.createElement(Pagination, { count: totalPages, page: currentPage, onChange: function (_, value) { return setCurrentPage(value); }, sx: {
                    display: "flex",
                    justifyContent: "center",
                    mt: 4,
                } }),
            React.createElement(Box, { sx: {
                    position: "sticky",
                    bottom: 0,
                    color: "inherit",
                    textAlign: "center",
                    zIndex: 1000,
                    padding: 2,
                    backgroundColor: "rgb(219, 228, 235)",
                } },
                React.createElement(Button, { variant: "contained", onClick: downloadAllCharts, sx: { width: "100%", height: "60px" } }, t("Download_All_Charts")))))))));
};
export { PrevalenceChart };
